<template>
  <div>
    <v-card fluid :dark="$dark.get()" class="modal">
      <v-card-title class="title"
        >{{
          isGroups
            ? "Управление группами характеристик"
            : "Управление характеристиками"
        }}
        <div>
          <v-btn
            small
            :color="!isGroups ? 'primary' : ''"
            @click="isGroups = false"
            >Характеристики</v-btn
          >
          <v-btn
            small
            :color="isGroups ? 'primary' : ''"
            @click="isGroups = true"
            >Группы</v-btn
          >
        </div>
      </v-card-title>
      <Groups v-if="isGroups" />
      <Features v-else />
    </v-card>
  </div>
</template>
<script>
import Features from "@/components/Features/Features";
import Groups from "@/components/Features/Groups";

export default {
  components: {
    Features,
    Groups,
  },
  data() {
    return {
      isGroups: true,
    };
  },
  async mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    gap: 10px;
  }
}
</style>
